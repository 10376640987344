const pdfGenerator = async (
  userName: string,
  courseName: string,
  clockHour: string,
  date: string,
  certificateUrl: string,
) => {
  try {
    const response = await fetch('/api/generate-certificate-pdf', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userName,
        courseName,
        clockHour,
        date,
        certificateUrl,
      }),
    });
    const blob = await response.blob();
    // window.open(pdfUrl, '_blank');
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, '_blank', 'noreferrer');
  } catch (error) {
    console.log({ error });
  }
};

export default pdfGenerator;
