import { useEffect, useCallback, useState, useContext } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { getCookie, deleteCookie } from 'cookies-next';
import { AppContext, eActionType } from '@contexts/appContext';
import { SocketContext } from '@contexts/SocketContext';
import LOG_HISTORIES from '@graphql/schema/mutations/logHistories.graphql';
import { isUserAuthenticated, authClientVar } from '@graphql/state';
import { OktaUserClaims } from '@types';
import { get } from 'lodash';

const useMyAccount = () => {
  const [userAccount, setUserAccount] = useState<OktaUserClaims>({} as OktaUserClaims);
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext);
  const { disconnectSocket } = useContext(SocketContext);
  const isLoggedIn = useReactiveVar(isUserAuthenticated);
  const authClient = useReactiveVar(authClientVar);

  const [logHistories] = useMutation(LOG_HISTORIES);

  const setUserAccountData = useCallback(async () => {
    const {
      accessToken,
      idToken: {
        claims: {
          witsbyContractGroupId = '',
          avatar = '',
          email = '',
          exp = 0,
          givenName = '',
          familyName = '',
          sub = '',
          witsbyRole = [],
        } = {},
      } = {},
    } = (await authClient.tokenManager.getTokens()) as any;
    setUserAccount({
      uid: get(accessToken, 'claims.uid', ''),
      avatar,
      email,
      exp,
      givenName,
      familyName,
      sub,
      witsbyRole,
      witsbyContractGroupId,
    });
  }, [authClient?.tokenManager]);

  useEffect(() => {
    if (authClient && isLoggedIn) {
      setUserAccountData();
    }
  }, [authClient, isLoggedIn, setUserAccountData]);

  const signOut = async () => {
    logHistories({
      variables: {
        data: userAccount || currentUser,
        logType: 'USER_ACTIVITY',
        actionType: 'LOGGED_OUT',
      },
    });
    disconnectSocket();
    deleteCookie('witsby-role');
    deleteCookie('magazineAccessed');
    const accessToken = getCookie('accessToken');

    dispatch({
      type: eActionType.RESET,
      data: null,
    });

    if (accessToken) {
      deleteCookie('accessToken');
      deleteCookie('refreshToken');
      localStorage.clear();
      window.location.href = `${process.env.SAML_URL}/auth/logout`;
    }
    const oktaAccessToken = get(
      localStorage.getItem('okta-token-storage')
        ? JSON.parse(localStorage.getItem('okta-token-storage') || '')
        : '',
      'accessToken.accessToken',
    );
    if (oktaAccessToken) {
      await authClient.closeSession();
      await authClient.revokeAccessToken();
      localStorage.clear();
      setUserAccount({} as OktaUserClaims);
      window.location.href = window.location.origin;
    }
  };

  return {
    user: userAccount,
    signOut,
  };
};

export default useMyAccount;
